<template>
  <b-card title="Araç Bilgisi">
    <b-list-group>
      <list-item
        title="Fatura Durumu"
        :value="swapcar.invoice_type === '0'? 'Faturasız' : 'Faturalı'"
      />
      <template>
        <list-item
          title="Marka"
          :value="swapcar.swap_brand"
        />
        <list-item
          title="Model"
          :value="swapcar.swap_model"
        />
      </template>
      <list-item
        title="Donanım Paketi"
        :value="swapcar.hardware"
      />
      <list-item
        title="Model Yılı"
        :value="swapcar.model_year"
      />
      <list-item
        title="Silindir Hacmi"
        :value="swapcar.cc"
      />
      <list-item
        title="Motor Gücü (Hp)"
        :value="swapcar.hp"
      />
      <list-item
        title="Renk"
        :value="swapcar.color"
      />
      <list-item
        title="Yakıt Tipi"
        :value="swapcar.fuel"
      />
      <list-item
        title="Vites Tipi"
        :value="swapcar.gear"
      />
      <list-item
        title="Şase No"
        :value="swapcar.chasis"
      />
      <list-item
        title="Plaka"
        :value="swapcar.license_plate"
      />
      <list-item
        title="Ruhsat Sahibi"
        :value="swapcar.license_owner"
      />
    </b-list-group>
    <div class="text-center">
      <b-button
        variant="primary"
        class="mt-2"
        :to="'/swapcars/edit/' + swapcar.id"
      >
        Bilgileri Güncelle
      </b-button>
    </div>
  </b-card>
</template>
<script>
import { BCard, BListGroup, BButton } from 'bootstrap-vue'
import ListItem from '@/views/Swapcars/view/ListItem.vue'

export default {
  name: 'Public',
  components: {
    BCard, BListGroup, BButton, ListItem,
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
}
</script>

<style scoped></style>
