<template>
  <b-row>
    <b-col cols="4">
      <personal />
    </b-col>
    <b-col>
      <public />
      <request-detail />
      <expertise />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Public from '@/views/Swapcars/view/Public.vue'
import RequestDetail from '@/views/Swapcars/view/RequestDetail.vue'
import Expertise from '@/views/Swapcars/view/Expertise.vue'
import Personal from '@/views/Customers/view/Personal.vue'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    Public,
    RequestDetail,
    Expertise,
    Personal,
  },
  computed: {
    getSwapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  watch: {
    getSwapcar(val) {
      if (val.id_com_customer) {
        this.getCustomer(val.id_com_customer)
      }
    },
  },
  created() {
    this.getSwapcarData()
  },
  methods: {
    getSwapcarData() {
      this.$store.dispatch('swapcars/swapcarView', this.$route.params.id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
  },
}
</script>

<style scoped></style>
